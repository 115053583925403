import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { AppService } from '../../../../app/app.service';
import { Catalog, Catalog_values, Variant, Product_req, Product_detail, Variant_req, Category } from '../../../../app/app.models';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '../../../services/products.service';
import { VariantsService } from '../../../services/variants.service';
import { CatalogsService } from '../../../services/catalogs.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { InputFile } from 'ngx-input-file';
import { HttpService } from '../../../../app/core/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface DialogData {
  categories: Variant[];
}
export interface VariantsAdded {
  variant_id: string;
  values:Array<string>;
}

@Component({
  selector: 'app-add-product-variant',
  templateUrl: './add-product-variant.component.html',
  styleUrls: ['./add-product-variant.component.scss']
})
export class AddProductVariantComponent implements OnInit {
  public form: UntypedFormGroup;
  public formTable: UntypedFormGroup;
  public selectedColors:string;
  public categorySelected:string;
  public categories:Category[];
  public tags:Catalog_values[];
  public variants: Variant[] = [];
  private sub: any;
  public id:any;
  public variant: string;
  public variantsCatalog: Variant[];
  public variantsCatalogTable: Variant[] = [];
  public variantsAdded: any[] = [];
  public tagsAdded: any[] = [];
  public variantsSaved:any[] = [0];
  private _variant:VariantsAdded;
  private req_product:Product_detail;
  private images:any;
  private urlImage:string;
  imageType:string = "image/png";

  constructor(public appService:AppService, 
    private productsService: ProductsService,
    private variantsService: VariantsService,
    private catalogsService: CatalogsService,
    public formBuilder: UntypedFormBuilder, 
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public router:Router,
    private spinner: NgxSpinnerService,
    private http: HttpService,
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.spinner.show();
    this.form = this.formBuilder.group({ 
      'name': [null, Validators.compose([Validators.required, Validators.minLength(4)])],
      'images': [null, Validators.required],
      "price": [0, Validators.required],      
      "description": null,
      "stock": [0, Validators.required], 
      "variant":null,
      "tags":null,
      "category": [null, Validators.required ],
      "combinations": null,
      "enabled":true,
      "couponEnabled":true,
      "freeShippingAvailable":true
    }); 
    this.formTable = this.formBuilder.group({}); 
    this.getCategories();
    this.getVariants();
    this.getTags();
  }

  public getCategories(){   
    if(this.appService.Data.categories.length == 0) { 
      console.log('servicio add')
      this.catalogsService.getCatalogsByLabel('Categorias').subscribe((data:Catalog) => {
        this.categories = data['catalog'][0].values; ;
        this.appService.Data.categories = data['catalog'][0].values; ;
      });
    }
    else{
      console.log('consulta add')
      this.categories = this.appService.Data.categories;
    }
    
  }
  public getTags(){   
    this.catalogsService.getCatalogsByLabel('tags').subscribe((data:Catalog) => {
      this.tags = data['catalog'][0].values; 
    }); 
  }
  public getVariantCatalog(catalog){
    var catalog_v = null;
    this.variantsCatalog.forEach(element => {
      if(element._id == catalog){
        catalog_v = element;
      }
    });
    return catalog_v;
  }

  public getProductById(){
    this.spinner.show();
    this.productsService.getProductById(this.id).subscribe((data:Product_req) => {
      this.form.patchValue(data['product'][0]); 
      this.form.controls['price'].setValue(data['product'][0].oldPrice);
      let combinations_v = data['product'][0].combinations;
      this.categorySelected = data['product'][0].category; 
      let variants_res = data['product'][0].variants;
      this.urlImage = data['product'][0].images[0]?.medium;

      if( data['product'][0].images[0]){
        let inputFile: InputFile = { id: data['product'][0]._id, preview: data['product'][0].images[0]?.medium/* , file: dataUrl */ };
          this.form.controls.images?.setValue([inputFile]);
      }
      

      /* Getting product variants */
      let variants: string[] = [];
      for(let vari=0; vari < variants_res.length;vari++){
         variants.push(variants_res[vari]._id);
         this.variantsAdded.push(variants_res[vari]._id);
      }
      this.form.controls["variant"].setValue(variants);
      /* End getting product variants */

      this.getVariantTableEdit(combinations_v);
      this.spinner.hide();
    });
  }

  toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader?.result);
        };
        reader?.readAsDataURL(xhr?.response);
    };
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.send();

    if(url.toUpperCase().includes(".PNG")){
      this.imageType = "image/png";
    }else if(url.toUpperCase().includes(".JPG") || url.toUpperCase().includes(".JPEG")){
      this.imageType = "image/jpg";
    }
}

  public getVariants(){
        this.variantsService.variant().subscribe((data:any) => {         
          this.variantsCatalog = data.variants;
          if(data.variants){
            this.sub = this.activatedRoute.params.subscribe(params => {  
              if(params['id']){
                this.id = params['id'];
                this.getProductById(); 
              } else{
                this.spinner.hide();
              }
            }); 
          }else{
            this.spinner.hide();
            this.snackBar.open('Ocurrió un error al cargar las variantes, por favor recarga la página.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          }  
          
        });
      }

  public getVariantTable(indexSaved){
    if(indexSaved == 0){
      this.variantsSaved = [];
    }
    this.variantsCatalogTable = [];
    if(this.variantsAdded.length>0){
      for(var i = 0; i < this.variantsAdded.length; i++) {
        const index: number = this.variantsCatalog.findIndex(x => x._id == this.variantsAdded[i]);
        this.variantsCatalogTable.push(this.variantsCatalog[index]);
        this.formTable.addControl(this.variantsCatalog[index]._id+"-TableVariant-"+indexSaved, new FormControl(null, Validators.required));
        
      }
      this.formTable.addControl("price-"+indexSaved, new FormControl(null, Validators.required));
      this.formTable.addControl("stock-"+indexSaved, new FormControl(null, Validators.required));
      this.variantsSaved.push(indexSaved);
    }else{
      this.variantsCatalogTable = [];
    }
  }

  public getVariantTableEdit(variants){
    this.variantsCatalogTable = [];
    this.variantsSaved = [];
    if(variants.length>0){
      for (let j=0;j<variants.length;j++){
        var object = Object.create(variants[j].variantsCombination);
        for (let object_ in object ) {
          const index: number = this.variantsCatalog?.findIndex(x => x._id == object_);
          if(j==0){
            this.variantsCatalogTable.push(this.variantsCatalog[index]);
          }
          this.formTable.addControl(this.variantsCatalog[index]._id+"-TableVariant-"+j, new FormControl(variants[j].variantsCombination[object_], Validators.required));
        }
        this.formTable.addControl("price-"+j, new FormControl(variants[j].price, Validators.required));
        this.formTable.addControl("stock-"+j, new FormControl(variants[j].stock, Validators.required));
        this.variantsSaved.push(j);
      }
    }else{
      this.variantsCatalogTable = [];
    }
  }
  public getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }

  public onSubmitTable(){
    if(this.formTable.valid){
      let jsonvariantsCombinations = "";
      let variantCombination = [];
      let combination:Variant_req={price: 0, stock: 0, variantsCombination: {}};
        for(let i=0;i<this.variantsSaved.length;i++){
          combination ={price: 0, stock: 0, variantsCombination: {}};
          for(let variant=0;variant< this.variantsAdded.length;variant++){
            jsonvariantsCombinations += '"'+this.variantsAdded[variant] +'" : "'+this.formTable.controls[this.variantsAdded[variant]+"-TableVariant-"+this.variantsSaved[i]].value+'"';
            if((variant+1) < this.variantsAdded.length){
              jsonvariantsCombinations += ",";
            }
          }
          combination.price = this.formTable.controls["price-"+this.variantsSaved[i]].value;
          combination.stock = this.formTable.controls["stock-"+this.variantsSaved[i]].value;

          combination.variantsCombination = JSON.parse("{"+jsonvariantsCombinations.replace("\\","")+"}");
          jsonvariantsCombinations = '';
          variantCombination.push(combination);
        }

        this.req_product = this.form.value;
        this.req_product.combinations = variantCombination;
        this.req_product.images = [];
        if(this.req_product.price==null)this.req_product.price=0;
        if(this.req_product.stock==null)this.req_product.stock=0;
        var json = '{"product":'+JSON.stringify(this.req_product)+'}';
      if(!this.id){
        this.callAddProduct(json);
      }else{
        this.callUpdateProduct(json);
      }
    }else{
      this.snackBar.open('Ninguna variante puede ir vacía', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
    }
    


    
    
  }

private callUpdateProduct(json){
  this.spinner.show();
    this.productsService.updateProduct(json,this.id).subscribe((data:any) => {   
     if(data.mensaje.indexOf('200--OK')>-1){
        this.snackBar.open('Producto actualizado con éxito.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        this.router.navigate(['admin/products/product-detail/'+this.id]);
      }else{
        this.spinner.hide();
        this.snackBar.open('Ocurrió un error al insertar el producto.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }  
    });
}

private callAddProduct(json){
  this.spinner.show();
  this.images = this.form.controls.images.value;  
  if( this.images != null && this.images.length > 0){
    this.productsService.addProduct(json).subscribe((data:any) => {   
      if(data.productId!= undefined && data.productId!= ""){
        this.sendImages(data.productId, "add", "Se insertó con éxito.");
      }else{
        this.snackBar.open('Ocurrió un error al insertar el producto.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        this.spinner.hide();
      }
    });
  }else{
    this.snackBar.open('Debe cargar una imagen con el producto.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
    this.spinner.hide();
  }
  
}
updateImage(){
  this.images = this.form.controls.images.value;
  if(this.images[0].file.name != undefined && this.images[0].file.type!=undefined ){
    this.spinner.show();
    this.sendImages(this.id, "update", "La imagen se actualizó con éxito.");
  }else{
    this.snackBar.open('La imagen no ha cambiado, si quieres actualizarla, carga una nueva por favor.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
  }
  
}

sendImages(id_temp_prod, operation, successMsg){
  let fileName_v = this.images[0].file.name != undefined ? this.images[0].file.name: "image."+this.imageType;
  let imageType_v = this.images[0].file.type != undefined?this.images[0].file.type:this.imageType;
  this.productsService.getImageURL(id_temp_prod+'?filename='+fileName_v).subscribe((data:any) =>{
    this.productsService.uploadFile(data.signedUrl,this.images[0].file,imageType_v).subscribe((data:any) =>{
      if(data.status != 200){
        if(operation == "add"){
          this.snackBar.open("Error: "+data.statusText , '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          this.productsService.deleteProductById(id_temp_prod).subscribe((res: any) => {
            console.log("Producto borrado por error de imagen");
          });
        }else{
          this.snackBar.open("Error al actualizar la imagen del producto: "+sessionStorage.getItem("ErrorImage") , '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }        
        sessionStorage.removeItem("ErrorImage");
        sessionStorage.removeItem("CodeResp");
        
      }else{
          this.snackBar.open(successMsg, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          if(operation == "add"){
            this.router.navigate(['/admin/products/product-list']);
          }else{
            this.router.navigate(['//admin/products/product-detail/'+id_temp_prod]);
          }
          
      }
      this.spinner.hide();
    })
  });
}

  public editProduct(){
    this.form.controls['name'].enable();
    this.form.controls['category'].enable();
    this.form.controls['description'].enable();
    this.form.controls['variant'].enable();
  }

  public onSubmit(){
    if(this.form.valid){
      if(this.variantsAdded.length==0){    
        this.form.controls['combinations'].setValue([]);
      }
      this.req_product = this.form.value;
      this.req_product.images = [];
      var json = '{"product":'+JSON.stringify(this.req_product)+'}';
      if(this.variantsAdded.length==0){      
        if(!this.id){
          this.callAddProduct(json);
        }else{
          this.callUpdateProduct(json);
        }
      }else{
        this.getVariantTable(0);
      }
    }else{
      this.snackBar.open("Favor de llenar todos los campos obligatorios", '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
    }
  }

  public addVariant(value){
    this.getVariantTable(value+1);
  }
  public removeVariant(value){
    if(this.variantsSaved.length == 1){
      this.variantsSaved = [];
    }else{
      this.variantsSaved.splice(value,1);
    }
    
  }
  

  public onVariantsChange(event:any){ 
    this.variantsAdded = event.value;
  }  

  public onTagsChange(event:any){ 
    this.tagsAdded = event.value;
  } 

  public onVariantSelectionChange(valueVariantId:any, variantId:any){ 
    this._variant = {variant_id:variantId,values:valueVariantId};
    var found = false;
    for(var i = 0; i < this.variantsAdded.length; i++) {
        if (this.variantsAdded[i].variant_id == variantId) {
          this.variantsAdded[i].values = valueVariantId;
          found = true;
          break;
        }
    }
    if(!found){
      this.variantsAdded.push(this._variant);
    }
    
  }  

  openDialog(): void {
    const dialogRef = this.dialog.open(VariantDialogComponent, {
      width: '250px',
      data: {categories : this.variantsCatalog},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result!= undefined){
        if(!this.form.controls.hasOwnProperty(result+"-Variant")){
            this.variants.push(this.getVariantCatalog(result));
            this.form.addControl(result+"-Variant", new FormControl(null, Validators.required));
        }else{
            this.snackBar.open('El producto ya tiene esa variante.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }
      }
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  } 

}

@Component({
  selector: 'variant-dialog',
  templateUrl: 'variant-dialog.component.html'
})
export class VariantDialogComponent {
  constructor(public dialogRef: MatDialogRef<VariantDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,) {}
      
      onNoClick(): void {
          this.dialogRef.close();
        }

        onSelectVariantType(valor){
          this.dialogRef.close(valor);
        }
}