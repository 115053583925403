import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { timeout, catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Router, NavigationEnd } from '@angular/router'; 


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private TIMEOUT = 180000;

  constructor(private http: HttpClient, public router:Router) { }

  sendRequest(url: string, method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH' , payload: any, usertType?:string): Observable<any> {
    let observable: Observable<any> = new Observable<any>();
    let lastAuthUser= localStorage.getItem("CognitoIdentityServiceProvider."+environment.congnitoConfig.Auth.Cognito.userPoolClientId+".LastAuthUser");
    let headers = new HttpHeaders().set('content-type', 'application/json'); // create header object

    let typeAfterValidate = this.sessionValidate(lastAuthUser);
    
    if(usertType != 'sinToken'){
      if(usertType == "admin" || usertType == "client"){
        headers = headers.append('Authorization', localStorage.getItem("CognitoIdentityServiceProvider."+environment.congnitoConfig.Auth.Cognito.userPoolClientId+"."+lastAuthUser+".accessToken")); 
      }else{
        usertType = localStorage.getItem("userLoggedDataBD") != null && 
        localStorage.getItem("CognitoIdentityServiceProvider."+environment.congnitoConfig.Auth.Cognito.userPoolClientId+"."+lastAuthUser+".accessToken")!== undefined && 
        localStorage.getItem("CognitoIdentityServiceProvider."+environment.congnitoConfig.Auth.Cognito.userPoolClientId+"."+lastAuthUser+".accessToken") !== null? "client" : "";
        if(usertType == "client"){
          headers = headers.append('Authorization', localStorage.getItem("CognitoIdentityServiceProvider."+environment.congnitoConfig.Auth.Cognito.userPoolClientId+"."+lastAuthUser+".accessToken")); 
        }else{
          headers = headers.append('Authorization', localStorage.getItem('tokenFuerza')); 
        }
      } 
    }

    if (method === 'GET') {
      observable = this.http.get(url, { headers}).pipe(
        timeout(this.TIMEOUT),
        catchError(err => of(this.catchError(err)))
      );
    } else if (method === 'POST') {
      observable = this.http.post(url, payload, { headers }).pipe(

        timeout(this.TIMEOUT),
        catchError(err => of(this.catchError(err)))
      );
    }else if (method === 'DELETE') {
      observable = this.http.delete(url, { headers }).pipe(
        timeout(this.TIMEOUT),
        catchError(err => of(this.catchError(err)))
      );
    }else if (method === 'PUT') {
      observable = this.http.put(url, payload, { headers }).pipe(

        timeout(this.TIMEOUT),
        catchError(err => of(this.catchError(err)))
      );
    }else if (method === 'PATCH') {
      observable = this.http.patch(url, payload, { headers }).pipe(
        timeout(this.TIMEOUT),
        catchError(err => of(this.catchError(err)))
      );
    }

    return observable;
  }

  sessionValidate(lastAuthUser){
    /* Se valida si existe el objeto de BD del usuario logueado en localstorage */
    if(localStorage.getItem("userLoggedDataBD") !== null){
      /* Se valida si existe el objeto de la sesión de cognito */
      if(lastAuthUser !== null && lastAuthUser !== undefined){
        /* Si existe objeto de BD y sesión de cognito */
        return "client";
      }else{
        /* Como no existe, pero si estaba logueado, se redirige a la página de login */
        this.router.navigate(['sign-in']);
        return "";
      }
    }else{
      /* No existe objeto de BD por lo tanto tampoco sesión de cognito y es un usuario invitado */
      return "";
    }
  }

  sendImageRequest(url: string, method:  'PUT' , payload: File, imgType:string): Observable<any> {
    let observable: Observable<any> = new Observable<any>();
    let headers = new HttpHeaders().set('Content-Type', 'application/octet-stream'); // create header object

    if (method === 'PUT') {
      observable = this.http.put(url, payload, { headers, observe: 'response'}).pipe(
        map(response => {
            return response;
          }
        ));  
    }
    return observable;
  }
//   private catchError(err: HttpErrorResponse): IResponse {
  private catchError(err: HttpErrorResponse) {
    if(err.status!= 200 && err.error != undefined){
      sessionStorage.setItem("CodeResp",err.status.toString());
      sessionStorage.setItem("ErrorImage",err.error.message);
    }
    let status = err.status ? err.status : 408;
    let statusText = err.statusText ? err.statusText : 'Request Timeout';
    let message = err.statusText ? `${err.name} ${err.message}` : '';

    if(err.message === "Cannot read properties of null (reading 'length')"){
      status = 440;
      statusText = 'La sesión ha terminado.';
    }
    // console.warn({ codigo: 1, mensaje: 'La operación excedió el tiempo de espera', datos: [{ status, statusText, message }] })
    return { codigo: 1, mensaje: status === 504 ? 'La operación excedió el tiempo de espera' : `Error ${status}--${statusText}`, datos: [{ status, statusText, message }], raw: err};
  }
}
