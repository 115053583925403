import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '../core/http.service';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private API = {
    URL_USERS: environment.backUrl+`users`
  };

  constructor(private httpService: HttpService, private http: HttpClient) { }

  public guestLogin() {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_USERS + '/guest_login', 'GET', '','sinToken');

    return observable;
  }
  public updateData(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_USERS , 'PATCH', params);

    return observable;
  }
  public getUserData() {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_USERS + '/client' , 'GET', '', 'client');

    return observable;
  }

}
