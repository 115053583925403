import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../core/http.service';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VariantsService {

  private API = {
    URL_VARIANTS: environment.backUrl+`variants`

  };

  constructor(private httpService: HttpService, private http: HttpClient) { }

  variant() {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_VARIANTS, 'GET', '','sinToken');

    return observable;
  }
  public variant_v1(operation, params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_VARIANTS, operation, params);

    return observable;
  }
  public getVariantById(id) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_VARIANTS+"/"+id, 'GET', '');

    return observable;
  }
  public insertVariant(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_VARIANTS, 'POST', params, "admin");

    return observable;
  }

  public deleteVariant(id) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_VARIANTS+"/"+id, 'DELETE', '', "admin");

    return observable;
  }
  public updateVariant(id, params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_VARIANTS+"/"+id, 'PUT', params, "admin");

    return observable;
  }
  public updateVariantValue(id, params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_VARIANTS+"/element/"+id, 'PUT', params, "admin");

    return observable;
  }


}
