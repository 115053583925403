import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Product, Catalog_values, Order, Ordenes, Category } from './app.models';
import { environment } from '../environments/environment';
import { OrdersService } from './services/orders.service';
import { User } from './admin/users/user.model';
import { UserLogguedData, UserLogguedBD } from '../app/models/user_loggued.model';
import { CognitoService } from '../app/services/cognito.service';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { Router, NavigationEnd } from '@angular/router';
import { UsersService } from '../app/services/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';


export class Data {
    constructor(public categories: Category[],
        public compareList: Product[],
        public wishList: Product[],
        public cartList,
        public totalPrice: number,
        public totalCartCount: number,
        public shippingPercentage: number,
        public remainingShippingAmount: number,
        public isFreeShipping: boolean,
        public freeShippingAvailable: boolean,
        public order?: Order,
        public userName?: string,
        public userMail?: string,
        public familyName?: string,
        public userLoggedData?: UserLogguedData,
        public userLoggedDataBD?: UserLogguedBD,
        public discountCouponCode?: string,) { }

}

@Injectable()
export class AppService {
    public Data = new Data(
        [], // categories
        [], // compareList
        [],  // wishList
        [],  // cartList
        null, //totalPrice,
        0,//totalCartCount,
        0, // shippingPercentage,
        0, // remainingShippingAmount
        false, // isFreeShipping
        false, // freeShippingAvailable
        null,// order
        "Iniciar sesión", //userName
        null, //userMail
        null, //familyName
        null, //userLoggedData
        null, //userLoggedDataBD
        null //discountCouponCode
    )

    public url = environment.url + '/assets/data/';

    constructor(public http: HttpClient, public snackBar: MatSnackBar, public ordersService: OrdersService, public cognitoService?: CognitoService, public router?: Router, public usersService?: UsersService, private spinner?: NgxSpinnerService,private cookies?: CookieService) { }

    // public getCategories(): Observable<Category[]> {
    //     return this.http.get<Category[]>(this.url + 'categories.json');
    // }

    public getProducts(type): Observable<Product[]> {
        return this.http.get<Product[]>(this.url + type + '-products.json');
    }

    public getProductById(id): Observable<Product> {
        return this.http.get<Product>(this.url + 'product-' + id + '.json');
    }

    public getBanners(): Observable<any[]> {
        return this.http.get<any[]>(this.url + 'banners.json');
    }

    public addToCompare(product: Product) {
        let message, status;
        if (this.Data.compareList.filter(item => item._id == product._id)[0]) {
            message = 'The product ' + product.name + ' already added to comparison list.';
            status = 'error';
        }
        else {
            this.Data.compareList.push(product);
            message = 'The product ' + product.name + ' has been added to comparison list.';
            status = 'success';
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public addToWishList(product: Product) {
        let message, status;
        if (this.Data.wishList.filter(item => item._id == product._id)[0]) {
            message = 'The product ' + product.name + ' already added to wish list.';
            status = 'error';
        }
        else {
            this.Data.wishList.push(product);
            message = 'The product ' + product.name + ' has been added to wish list.';
            status = 'success';
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public addToCart(product: Product) {
        let message, status;

        this.Data.totalPrice = 0;
        this.Data.totalCartCount = 0;
        // this.Data.cartList.filter(item => item._id == product._id)[0]
        if (this.Data.cartList.filter(item => item._id == product._id)[0]) {
            let item = this.Data.cartList.filter(item => item._id == product._id)[0];
            item.cartCount = product.cartCount;
        }
        else {
            this.Data.cartList.push(product);
        }
        // this.Data.cartList.forEach(product => {
        //     this.Data.totalPrice = this.Data.totalPrice + Number(product.price.$numberDecimal);
        //     this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
        // });
        this.getOrder();

        // localStorage.setItem('cart_items', JSON.stringify(this.Data.cartList));
        // localStorage.setItem('totalPrice', this.Data.totalPrice.toString());
        // localStorage.setItem('totalCartCount', this.Data.totalCartCount.toString());

        message = 'El producto ' + product.name + ' ha sido añadido al carrito.';
        status = 'success';

        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public resetProductCartCount(product: Product) {
        product.cartCount = 0;
        let compareProduct = this.Data.compareList.filter(item => item._id == product._id)[0];
        if (compareProduct) {
            compareProduct.cartCount = 0;
        };
        let wishProduct = this.Data.wishList.filter(item => item._id == product._id)[0];
        if (wishProduct) {
            wishProduct.cartCount = 0;
        };
    }

    getOrder() {
        this.ordersService.getOrdersBytoken().subscribe((res: Ordenes) => {
            if (res.order.products) {
                this.Data.totalPrice = res.order.amount;
                this.Data.totalCartCount = res.order.quantity;
                this.Data.shippingPercentage = res.order.shippingPercentage;
                this.Data.remainingShippingAmount = res.order.remainingShippingAmount;
                this.Data.isFreeShipping = res.order.isFreeShipping;
                this.Data.cartList = res.order.products;
                this.Data.freeShippingAvailable = res.order.freeShippingAvailable;
                this.Data.discountCouponCode = res.order.discountCouponCode;
            }
            this.Data.order = res.order;
        });
    }

    public getBrands() {
        return [
            { name: 'aloha', image: 'assets/images/brands/aloha.png' },
            { name: 'dream', image: 'assets/images/brands/dream.png' },
            { name: 'congrats', image: 'assets/images/brands/congrats.png' },
            { name: 'best', image: 'assets/images/brands/best.png' },
            { name: 'original', image: 'assets/images/brands/original.png' },
            { name: 'retro', image: 'assets/images/brands/retro.png' },
            { name: 'king', image: 'assets/images/brands/king.png' },
            { name: 'love', image: 'assets/images/brands/love.png' },
            { name: 'the', image: 'assets/images/brands/the.png' },
            { name: 'easter', image: 'assets/images/brands/easter.png' },
            { name: 'with', image: 'assets/images/brands/with.png' },
            { name: 'special', image: 'assets/images/brands/special.png' },
            { name: 'bravo', image: 'assets/images/brands/bravo.png' }
        ];
    }

    public getCountries() {
        return [
            { name: 'México', code: 'MX' }
        ]
    }

    public getMonths() {
        return [
            { value: '01', name: 'January' },
            { value: '02', name: 'February' },
            { value: '03', name: 'March' },
            { value: '04', name: 'April' },
            { value: '05', name: 'May' },
            { value: '06', name: 'June' },
            { value: '07', name: 'July' },
            { value: '08', name: 'August' },
            { value: '09', name: 'September' },
            { value: '10', name: 'October' },
            { value: '11', name: 'November' },
            { value: '12', name: 'December' }
        ]
    }

    public getYears() {
        return ["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"]
    }

    public getDeliveryMethods() {
        return [
            { value: 'fijos', name: 'Puntos fijos de entregas', desc: '' },
            { value: 'domicilio', name: 'Servicio a domicilo', desc: '(Costo aproximado de $30 - $80 pesos. En pedidos mayores de $1600 ENVIO GRATIS dentro de CUU capital)' },
            { value: 'didi', name: 'Envíos por DIDI', desc: '(El envío se paga al recibir)' }
        ]
    }

    public getPaymentMethods() {
        return [
            { value: 'cash', name: 'Efectivo', desc: ' (Contra entrega)' },
            { value: 'card', name: 'Pago en línea', desc: ' (Ingresa tus datos)' },
            { value: 'transfer', name: 'Transferencia', desc: '(Nos comunicaremos contigo / Recibiras los datos por WhatsApp)' }
        ]
    }

    async checkLoggedUser(): Promise<boolean> {
        try {
            await this.checkUserStatus();
            const userLoggedDataBD = localStorage.getItem("userLoggedDataBD");
            if (!userLoggedDataBD || userLoggedDataBD === '' || userLoggedDataBD === 'undefined' || userLoggedDataBD === null) {
                this.getUserDataBD(() => {
                    this.getBytoken(() => { })
                });
            } else {
                this.Data.userLoggedDataBD = JSON.parse(userLoggedDataBD);
                this.Data.userName = this.Data.userLoggedDataBD.name;
            }
            return true
        }
        catch (error) {
            await this.checkUserStatus()
            console.error('Authentication error:', error);
            this.spinner.hide();
            this.Data.userName = "Iniciar sesión";
            return false
        }
    }

    public async checkUserStatus() {
        const { tokens } = await fetchAuthSession({ forceRefresh: true });
        const accessToken = tokens?.accessToken;
        localStorage.setItem('tokenFuerza', accessToken.toString());

        const user = await getCurrentUser();
        
        if (!accessToken || !user) {
            throw new Error('Failed to retrieve authenticated user.');
        }
    }

    signOut() {
        

        localStorage.removeItem("userLoggedDataBD");
        localStorage.removeItem("userMail");
        this.Data.userName = "Iniciar sesión";

        this.cognitoService.signOut().
            then(() => {
                
                this.router.navigate(['/sign-in']);
                this.getOrder();
            }).catch((error: any) => {
                console.log("Error al cerra sesión: " + error);
            })

    }

    async rolUser() {
        let groups = [];
        const { accessToken } = (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};
        if (accessToken) {
            const payloadGroups = accessToken.payload['cognito:groups'];
            if (Array.isArray(payloadGroups)) {
                groups = payloadGroups;
            }
            if (groups.includes('adminGroup') || groups.includes('employeeGroup')) {
                const accessGroup = groups.includes('adminGroup') ? 'admin' : 'employee';
                this.cookies.set('accessGroup', accessGroup);
                this.router.navigate(['admin/sales/orders'])
            } else {
                this.router.navigate(['account/dashboard']);
            }
        } else {
            this.router.navigate(['/sign-in'])
        }
    }

    getUserDataBD(resolve) {
        this.usersService.getUserData().subscribe((res: any) => {
            if(res.mensaje === undefined || res.mensaje?.includes('Error')){
                localStorage.setItem("userLoggedDataBD", JSON.stringify(res['user']));
                this.Data.userLoggedDataBD = res['user'];
                this.Data.userName = res['user']['name'];
                if (resolve) {
                    resolve();
                }
            }else{
                console.error("Error en servicio de User Data: "+res.mensaje);
            }
            
        });
    }


    public getBytoken(resolve) {
        this.ordersService.getOrdersBytoken().subscribe((res: Ordenes) => {
            if (res.order?.products) {
                this.Data.totalPrice = res.order.amount;
                this.Data.totalCartCount = res.order.quantity;
                this.Data.shippingPercentage = res.order.shippingPercentage;
                this.Data.remainingShippingAmount = res.order.remainingShippingAmount;
                this.Data.isFreeShipping = res.order.isFreeShipping;
                this.Data.freeShippingAvailable = res.order.freeShippingAvailable;
                this.Data.cartList = res.order.products;
            }
            this.Data.order = res.order;
            if (resolve) {
                resolve();
            }
        });

    }

    linkOrder() {
        this.ordersService.linkOrder().subscribe((res: any) => {
            console.log("Carrito actualizado");
        });
    }

    afterRRSSLoginSuccess() {
        this.linkOrder();
        this.getOrder();
    }

    public async isUserLoggedIn(): Promise<boolean> { 
        try {
            const { tokens } = await fetchAuthSession({ forceRefresh: true }); 
            const accessToken = tokens?.accessToken; 
            const user = await getCurrentUser(); 
            
            return !!(accessToken && user); 
        } catch (error) {
            return false;
        }
        
    }
} 