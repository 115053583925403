import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from './services/users.service';
import { OrdersService } from './services/orders.service';
import { AppService } from './app.service';
import { GuestLogin, Ordenes, Catalog } from './app.models';
import { FreeShippingDialogComponent } from './ free-shipping/ free-shipping-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading: boolean = false;
  public settings: Settings;
  constructor(public appSettings: AppSettings,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public translate: TranslateService,
    public usersService: UsersService,
    public ordersService: OrdersService,
    public appService: AppService,
    public dialog: MatDialog,
    private cookieService: CookieService
  ) {
    this.settings = this.appSettings.settings;
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('es');
    translate.use('es');
  }

  ngOnInit() {
    // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh  
    if (!localStorage.getItem('tokenFuerza') || localStorage.getItem('tokenFuerza') === "undefined" || localStorage.getItem('tokenFuerza') === null) {
      this.getToken();
    } else {
      this.getOrder();
    }
    /** ToDo: Se comenta porque no hay sesión de usuario */
/*     if(localStorage.getItem("userLoggedDataBD") !== undefined){
      this.appService.checkLoggedUser();
    } */
  }
  getToken() {
    this.usersService.guestLogin().subscribe((res: GuestLogin) => {
      const response: GuestLogin = {
        token: res.token
      };
      localStorage.setItem('tokenFuerza', response.token);
      this.getOrder();
    });
  }
  getOrder() {
    if (!localStorage.getItem('tokenFuerza') || localStorage.getItem('tokenFuerza') == "undefined") {
      this.usersService.guestLogin().subscribe((res: GuestLogin) => {
        const response: GuestLogin = {
          token: res.token
        };
        localStorage.setItem('tokenFuerza', response.token);
        this.getBytoken();
      })
    } else {
      this.getBytoken();  
    }
  }

  public getBytoken() {
    this.ordersService.getOrdersBytoken().subscribe((res: Ordenes) => {
      if (res.order?.products) {
        this.appService.Data.totalPrice = res.order.amount;
        this.appService.Data.totalCartCount = res.order.quantity;
        this.appService.Data.shippingPercentage = res.order.shippingPercentage;
        this.appService.Data.remainingShippingAmount = res.order.remainingShippingAmount;
        this.appService.Data.isFreeShipping = res.order.isFreeShipping;
        this.appService.Data.freeShippingAvailable = res.order.freeShippingAvailable;
        this.appService.Data.cartList = res.order.products;
      }
      this.appService.Data.order = res.order;
       this.validCP();
    });

  }

  public validCP() {
    const regex = /^\/products.*/;
    const { Data } = this.appService;
  
    const isCartOrProductPage = window.location.pathname === '/cart' || regex.test(window.location.pathname) || window.location.pathname === '/';
    const isDeliveryAddressEmpty = !Data.order?.deliveryAddress?.zipcode;
  
    if (isCartOrProductPage && isDeliveryAddressEmpty) {
      this.dialog.open(FreeShippingDialogComponent, {});
    }
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      }
    })
  }
}
