/*DEV LASH*/
export const environment: {
  production: any,
  url: any,
  version: any,
  publicKeyMP: any,
  backUrl: any,
  awsApiKey: any,
  congnitoConfig: any
} = {
  production: true,
  url: '',
  version: '2.0',
  publicKeyMP: 'TEST-dd22db25-9c8c-4e5c-a6f5-00ad4a98de3c',
  backUrl: 'https://ch6u5vc4zl.execute-api.us-east-1.amazonaws.com/dev/',
  awsApiKey: 'v1.public.eyJqdGkiOiJiM2E4NDM2My04ZThjLTRhMmUtOWE5YS00MDllMWE2NjAyMjMifQpTq32pLyWzHpnvYxq-HnUOT-Awy4WSOkLYWPE6nqVTFqQMD9lOThY3jEFe27Bg8qElYstPnCjr_bhKHSZw5n7ucLEG4Qy24WFn96UCYDdoiSdIKiwAlEE6pkc_OcMXIIJOUZajOW3V3RUWbjpeT7dsNZJSP6oUkDoUXdXIF4SYzaP9J_GIrKqILZ7DXGLDQPigodp4Jf4G8KGI_r5aAVcz18R575f9PitibEyNzB-NQhpbZ28tXaO_zXinS0OReyAUltkrP0fOnQnvClw2Ac0d2Z4SmYbOh0w7JAUFl6hjRyfKhNqgLuT06gSRimCLAxhe6TXQ-qa4DdApugwB3QY.ZWU0ZWIzMTktMWRhNi00Mzg0LTllMzYtNzlmMDU3MjRmYTkx',
  congnitoConfig: {
    Auth: {
      Cognito: {
        aws_cognito_region: 'us-east-1',//Se utiliza para el autocompletado de las direcciones en checkout/delivery
        //  Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_oKVzp6ERm',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: '6m6m6ljnmbvjs0fin0ftu9tep0',
        signUpVerificationMethod: 'code', // 'code' | 'link'
        loginWith: {
          // OPTIONAL - Hosted UI configuration
          oauth: {
            domain: 'dev-fuerza-suprema.auth.us-east-1.amazoncognito.com',
            scopes: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
            redirectSignIn: ['http://localhost:4200/account/dashboard?source=rrss'],
            redirectSignOut: ['http://localhost:4200/sign-in'],
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
          }
           // oauth: {
          //   domain: 'devlashmx.auth.us-east-1.amazoncognito.com',
          //   scopes: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
          //   redirectSignIn: ['https://dev.lashmx.mx/account/dashboard?source=rrss'],
          //   redirectSignOut: ['https://dev.lashmx.mx/sign-in'],
          //   responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
          // }
        }
      }
    }
  }
};