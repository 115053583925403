import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { promise } from 'protractor';
import { OrdersService } from './orders.service';
import { getCurrentUser, fetchAuthSession, signOut, signIn, forgetDevice, signUp, confirmSignUp, resetPassword, confirmResetPassword, updatePassword } from 'aws-amplify/auth';


@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  constructor(public ordersService: OrdersService) {
    Amplify.configure(environment.congnitoConfig)
  }

  public signUp(user: User): Promise<any> {
    return signUp({
      username: user.email,
      password: user.password,
      options: {
        userAttributes: {
          email: user.email,
          given_name: user.givenName,
          family_name: user.familyName
        }
      }
    })
  }

  public confirmSignUp(user: User): Promise<any> {
    return confirmSignUp({ username: user.email, confirmationCode: user.code });
  }

  public getUser(): Promise<any> {
    return getCurrentUser()
  }

  public async getSession() {
    try {
      return await fetchAuthSession()
    } catch (error) {
      console.log("error: " + error.message);
    }
  }

  public signIn(user: User): Promise<any> {
    return signIn({ username: user.email, password: user.password })
  }

  public signOut(): Promise<any> {
    return signOut();
  }

  public forgotPassword(user: string): Promise<any> {
    return resetPassword({ username: user });
  }

  public async forgotPasswordSubmit(user: User, new_password: string): Promise<any> {
    try {
      return await confirmResetPassword({ username: user.email, confirmationCode: user.code, newPassword: new_password });
    } catch (error) {
      console.log("error: " + error.message);
    }
  }

  public async changePasswordSubmit(oldPassword: string, new_password: string): Promise<any> {
    try {
      return await updatePassword({ oldPassword, newPassword: new_password });
    } catch (error) {
      console.log("error: " + error.message);
    }
  }






}
