export class Category {
  constructor(public id: number,
    public name: string,
    public hasSubCategory: boolean,
    public parentId: number) { }
}

// export class Product {
//   constructor(public id: number,
//  public name: string,
//               public images: Array<any>,
//               public oldPrice: number,
//               public newPrice: number,
//               public discount: number,
//               public ratingsCount: number,
//               public ratingsValue: number,
//               public description: string,
//               public availibilityCount: number,
//               public cartCount: number,
//               public color: Array<string>,
//               public curva: Array<string>,
//               public medida: string,
//               public size: Array<string>,
//               public weight: number,
//               public categoryId: number){ }
// }

export class ProductArray {
  public products: Product[]
}

export class Product {
  constructor(public _id: string,
    public id: number,
    public variants: Variant[],
    public category: string,
    public price: number,
    public hasCoupon: string,
    public name: string,
    // public stock:            Image[],
    public oldPrice:          number,

    // public discount:          null,
    // public ratingsCount:      number,
    public images: Image[],
    public ratingsValue: number,
    public description: string,
    public stock: number,
    public cartCount: number,
    // public color:          Variant[],

    // public size:              string[],
   
    public medida: string,
    public weight: number,
    public combinations: Combination[],
    public categoryId: number) { }
}

export interface Combination {
  price: number;
  hasCoupon: string,
  stock: number;
  variantsCombination: string[];
  _id: string;
}

export interface Image {
  small: string;
  medium: string;
  big: string;
}

export interface Variant {
  label: string;
  type: string;
  selected: string;
  catalog: string[];
  values: Value[];
  id: string;
}

export interface Value {
  id: string;
  default: boolean;
  label: string;
  type: string;
  values: Values[];
  isDefault: boolean;
  enabled: boolean;
  _id: string;
}
export interface Values {
  label: string;
  isDefault: boolean;
  images: Image[];
  _id: string;
}

/* Admin */

export class Product_res {
  constructor(public products: Product_[]) { }
}
export interface Product_ {
  _id: string;
  variants: Variant[];
  category: string;
  price: number;
  oldPrice:number;
  hasCoupon: string;
  name: string;
  description: string;
  stock: number;
  images: Image[];
  combinations: Variant_req[];
  tags:string[];
  enabled:boolean;
  couponEnabled:boolean;
  freeShippingAvailable:boolean;
}

/* Admin Add product */

export class Product_req {
  constructor(public product: Product_detail) { }
}
export interface Product_detail {
  category: string;
  price: number;
  name: string;
  stock: number;
  images: Image[];
  description: string;
  combinations: Variant_req[];
  variants: Variant_values[];
  enabled: boolean;
  couponEnabled:boolean;
  freeShippingAvailable:boolean;
}
export class Variant_values {
  constructor(public values: Variant[]) { }
}

export interface Product_detail_update {
  _id: string;
  category: string;
  price: number;
  hasCoupon: string;
  name: string;
  stock: number;
  images: Image[];
  description: string;
  variants: Variant_req[];
}
export interface Variant_req {
  price: number;
  // hasCoupon: string;
  stock: number;
  variantsCombination: any;
}

/* Catalogs */

export class Catalogs {
  constructor(public catalog: Catalog[]) { }
}
export interface Catalog {
  _id: string;
  label: string;
  values: Catalog_values[];
}
export interface Catalog_values {
  _id: string;
  label: string;
  value: string;
  checked: boolean;
}


/* Variants */
export class Variant_res {
  constructor(public variants: Variant[]) { }
}

export interface Variant {
  _id: string;
  values: Value[];
  label: string;
}

//////////// Combination ///////////////

export interface Combination {
  stock: number;
  price: number;
  oldPrice: number;
  hasCoupon: string;
}

export interface CombinationArray {
  combination: Combination;
}

export interface GuestLogin {
  token: string;
}


//////////// ordenes ///////////////

export interface VariantsCombination {
}

export interface Product {
  productId: string;
  quantity: number;
  variantsCombination: VariantsCombination;
  _id: string;
  name: string;
  description: string;
  price: number;
  hasCoupon: string;
  stock: number;
  amount?: any;
  available: boolean;
  enabled: boolean;
}

export interface Order {
  _id: string;
  status: string;
  token: string;
  paymentMethod: string;
  products: Product[];
  createdAt: Date;
  payedAt: Date;
  amount?: any;
  __v: number;
  deliveryInfo:DeliveryInfo;
  deliveryAddress:DeliveryAddress;
  quantity: number;
  originalAmount?: number;
  discountAmount?:number;
  coupons: string;
  paymentMethodLabel: string;
  statusLabel:string;
  isFreeShipping:boolean;
  shippingPercentage: number;
  remainingShippingAmount: number;
  freeShippingAvailable: boolean;
  discountCouponCode: string;
  shortId: string;
}

export interface Ordenes {
  order: Order;
}

  export interface DeliveryInfo {
    name: string;
    lastName1: string;
    lastName2: string;
    email: string;
    phone: string;
    _id:string;
  }

  export interface DeliveryAddress {
    street: string;
    numberStreet: string;
    neighborhood: string;
    city: string;
    country: string;
    state:string;
    zipcode:string;
    distance:string;
    deliveryAmount:string;
    deliveryMethodId: string;
    deliveryMethod: string;
    deliveryMethodLabel :string;
    deliveryTime: string;
    deliveryTimeLabel: string;
    warehouse:Warehouse;
    _id:string;
  }


/* Warehouses */

export class Warehouses {
  constructor(public warehouses: Warehouse[]) { }
}
export interface Warehouse {
  _id: string;
  name: string;
  street: Catalog_values[];
  numberStreet: string;
  neighborhood: string;
  town: string;
  city: string;
  state: string;
  aditional: string;
  fullAddress: string;
  default: boolean;
  zipcode: string;
  country: string;
  lat: number;
  lng: number;
}

export interface ResPost {
  codigo: number;
  datos: any[];
  mensaje: string;
}

export interface DiscountCoupon{
  isValid: boolean;
  message: string;
}

export interface Quantity {
  quantity: number;
}



/* Coupons */


export class Coupons {
  constructor(public coupons: Coupon[]) { }
}
export interface Coupon {
  _id : string;
  quantity: number;
  product: string,
  discount: number,
  label: string,
  minimum: number,
  maximum: number,
  couponType: string,
  applyTo: string
}
export interface CouponType {
  label: string;
  value: string;
}

/* Coupons */

/* Admin loguin */

export interface AdminLogin {
  token: string;
}
/* Admin loguin */


/* User */

export interface User {
  _id : string;
  name: string;
  token: string;
  deliveryInfo:DeliveryInfo;
  deliveryAddress:DeliveryAddress;
}

/* User */