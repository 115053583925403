<mat-sidenav-container class="principal_container">

    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
            <mat-icon color="warn">close</mat-icon>
        </button>
        <div class="divider"></div>
        <app-sidenav-menu [menuItems]="sidenavMenuItems" [menuParentId]="0"></app-sidenav-menu>
        <div class="divider"></div>
        <div class="text-center py-2">
            <a href="https://www.facebook.com/profile.php?id=100075686328256">
                <img class="social-icon" [src]="'assets/images/others/facebook_icon.png'" alt="">
            </a>
            <a href="https://www.instagram.com/lashmx_/">
                <img class="social-icon" [src]="'assets/images/others/instagram_icon.png'" alt="">
            </a>
        </div>
    </mat-sidenav>

    <mat-toolbar color="primary" id="header-toolbar">

        <mat-toolbar-row fxLayoutAlign="space-between center" class="logo-toolbar theme-container">

            <div fxFlex="25" class="img_container" (click)="closeSubMenus()">
                <a class="logo" routerLink="/" (click)="closeSubMenus()">
                    <img [src]="'assets/images/brands/logo/logo_fuerzaSuprema_v2.jpg'" alt="">
                </a>
            </div>
            
            <div fxFlex fxShow="false" fxShow.gt-sm fxFlex="50">
                <div fxLayout="column" fxLayoutAlign="center center" class="top-cart envio" *ngIf="appService.Data.freeShippingAvailable">
                    <span *ngIf="!appService.Data.isFreeShipping">
                        <mat-icon class="mat-icon-sm">local_taxi</mat-icon> Te faltan
                        ${{appService.Data.remainingShippingAmount | number : '1.2-2'}} para que tu envío sea gratis.
                    </span>
                    <span *ngIf="!appService.Data.isFreeShipping">
                        Solo para Chihuahua capital.
                    </span>
                    <span *ngIf="appService.Data.isFreeShipping">
                        <mat-icon class="mat-icon-sm">local_taxi</mat-icon> Envío gratis dentro de Chihuahua capital.
                    </span>
                    <div class="progress">
                        <div [ngClass]="[appService.Data.shippingPercentage <= 25 ? 'zero' : '', 
                            (appService.Data.shippingPercentage > 25 && appService.Data.shippingPercentage <= 50) ? 'thirty' : '', 
                            (appService.Data.shippingPercentage > 50 && appService.Data.shippingPercentage <= 75) ? 'fifty' : '',  
                            (appService.Data.shippingPercentage > 75 && appService.Data.shippingPercentage <= 99) ? 'eighty' : '',
                            appService.Data.shippingPercentage == 100 ? 'onehundred' : '']"
                            class="progress-bar" [style.width.%]="appService.Data.shippingPercentage"></div>
                    </div>
                </div>
        
                <div fxLayout="column" fxLayoutAlign="center center" class="top-cart envio" *ngIf="!appService.Data.freeShippingAvailable">
                    <span>
                        <mat-icon class="mat-icon-sm">local_taxi</mat-icon>{{'SHIPPING_MSG' | translate}}
                    </span>
                </div>
                <form method="get" class="search-form" fxLayout="row" [formGroup]="form">
                    <!-- <button mat-raised-button [matMenuTriggerFor]="categoriesMenu" #categoriesMenuTrigger="matMenuTrigger" type="button" class="mat-elevation-z0 categories text-truncate text-muted">{{category?.name}}<mat-icon>arrow_drop_down</mat-icon></button>
                    <mat-menu #categoriesMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="categories-dropdown">
                        <span (mouseleave)="categoriesMenuTrigger.closeMenu()">
                            <app-category-list [categories]="categories" [categoryParentId]="0" (change)="changeCategory($event)"></app-category-list>
                        </span>
                    </mat-menu> -->
                    <input type="text" placeholder="Introduce tu búsqueda" formControlName="search_value" fxFlex
                        (keyup)="search()">
                    <button mat-mini-fab (click)="search()" type="button"
                        class="search-btn mat-elevation-z0 text-muted">
                        <mat-icon>search</mat-icon>
                    </button>
                </form>
            </div>
        
            <div fxFlex="25" fxLayout="row" fxLayoutAlign="end center" class="img_container">
                <div fxLayoutAlign="center center" [@.disabled]="true">
                    <!-- <button mat-button [matMenuTriggerFor]="cartMenu" #cartMenuTrigger="matMenuTrigger"
                        class="flex-row-button" (click)="updateCart()"> -->
                        <button mat-button class="flex-row-button" (click)="openCart()" matTooltip="Ver carrito">
                        <mat-icon class="mat-icon-lg">shopping_cart_checkout</mat-icon>
                        <span *ngIf="appService.Data.totalCartCount > 0"
                            class="cart-items-count">{{appService.Data.totalCartCount}}</span>
                        <div fxLayout="column" fxLayoutAlign="center center" fxShow="false" fxShow.gt-xs
                            class="top-cart">
                            <!-- <span>{{ 'MSG_CART' | translate }}</span> -->
                            <span *ngIf="appService.Data.totalCartCount > 0">
                                <bdi>{{appService.Data.totalCartCount}} item<span
                                        *ngIf="appService.Data.totalCartCount > 1">s</span> -
                                    $<span>{{appService.Data.totalPrice | number : '1.2-2'}}</span>
                                </bdi>
                            </span>
                        </div>
                    </button>
             
                </div>

        

                <!-- <div fxLayoutAlign="center center" fxHide="false" fxHide.gt-sm [@.disabled]="true">
                    <button mat-icon-button [matMenuTriggerFor]="searchMenu" #searchMenuTrigger="matMenuTrigger"
                        class="search-toggle-btn">
                        <mat-icon class="mat-icon-lg">search</mat-icon>
                    </button>
                    <mat-menu #searchMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                        class="search-dropdown mat-elevation-z8">
                        <ng-template matMenuContent>
                            <form method="get" fxFlex class="search-form" [formGroup]="form">
                                <input type="text" placeholder="Introduce tu búsqueda" formControlName="search_value"
                                    fxFlex (click)="stopClickPropagate($event)" (keyup)="search()">
                                <button mat-mini-fab (click)="search()" type="button"
                                    class="search-btn mat-elevation-z0 text-muted">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </form>
                        </ng-template>
                    </mat-menu>
                </div> -->
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-toolbar color="primary" id="header-toolbar" fxHide.gt-sm fxShow.lt-md>
        <div fxLayout="column" fxLayoutAlign="center center" class="top-cart search">
            <form method="get" class="search-form" fxLayout="row" [formGroup]="form">
                <input type="text" placeholder="Introduce tu búsqueda" formControlName="search_value" fxFlex
                 >
                <button mat-mini-fab (click)="search()" type="button" class="search-btn mat-elevation-z0 text-muted">
                  <mat-icon>search</mat-icon>
                </button>
              </form>
        </div>
    </mat-toolbar>
   



    <app-menu></app-menu>


    <div class="theme-container main">
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
    </div>


    <!-- <app-options></app-options> -->

    <app-footer></app-footer>

    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition"
        (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>


</mat-sidenav-container>