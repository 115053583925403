<footer class="bg-primary footer">
    <!--div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="border-bottom-mute subscribe-block theme-container">
        <div fxFlex="100" fxFlex.gt-sm="40" fxLayout="column" fxLayoutAlign="center center"> 
            <h1 class="fw-500">Subscribe our Newsletter</h1>
            <h3 class="fw-300 secondary-color">Stay up to date with our latest new and products</h3>
        </div>
        <form method="get" class="subscribe-search-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4" ngClass.sm="mt-2" ngClass.xs="mt-2">
            <input type="text" placeholder="Your email address..." fxFlex>
            <button mat-raised-button color="accent" (click)="subscribe()" type="button" class="mat-elevation-z0 text-muted">Subscribe</button>
        </form>
    </div-->
    <div fxLayout="row wrap" fxLayoutAlign="center" class="py-3 border-bottom-mute theme-container footer_cotainer">        
        <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="80" > 
            <h3 class="col-title">{{ 'SITE_MAP.TITLE' | translate }}</h3>    
            <!--p class="mt-2"><a href="javascript:void(0);" class="link secondary-color">My Account</a></p-->
            <div class="mt-1"><a target="_blank" href="https://api.whatsapp.com/send?phone=+529982600136&text=¡Hola! Me interesan sus productos, necesito ayuda." class="link secondary-color">Contacto</a></div>
            <div class="mt-1"><a routerLink="/products" class="link secondary-color">{{ 'SITE_MAP.ET1' | translate }}</a></div>
            <!--p class="mt-1"><a routerLink="/wishlist" class="link secondary-color">Wishlist</a></p-->
            <div class="mt-1"><a routerLink="/cart" class="link secondary-color">{{ 'SITE_MAP.ET2' | translate }}</a></div>
            <div class="mt-1"><a href="/faqs" class="link secondary-color">{{ 'SITE_MAP.ET3' | translate }}</a></div>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="80" ngClass.xs="mt-2" class="contact-information"> 
            <h3 class="col-title">{{ 'CONTACT.TITLE' | translate }}</h3>            
            <!-- <div fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color">
                <mat-icon>location_on</mat-icon>
                <span>2903 Avenue Z, Brooklyn, NY</span>
            </div> -->
            <div fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>call</mat-icon>
                <span><a _ngcontent-serverapp-c360="" href="tel:+5216141495020" class="phone_link">{{ 'CONTACT.PHONE' | translate }}</a></span>
            </div>
            <a href="mailto:{{ 'CONTACT.EMAIL' | translate }}">
                <div fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                    <mat-icon>mail_outline</mat-icon>
                    <span>{{ 'CONTACT.EMAIL' | translate }}</span>
                </div>
            </a>
            <app-top-menu></app-top-menu>
        </div>


        <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="80" ngClass.xs="mt-2" class="contact-information"> 
            <h3 class="col-title">{{ 'FOOTER.TITLE' | translate }}</h3>            
            <div fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <img src="../../../../assets/images/others/facebook_icon.png" alt="" class="rrss_icon">
                <span><a _ngcontent-serverapp-c360="" href="{{'FOOTER.FACEBOOK_LINK' | translate}}" class="phone_link">{{ 'FOOTER.FACEBOOK_TEXT' | translate }}</a></span>
            </div>
            <a href="mailto:{{ 'CONTACT.EMAIL' | translate }}">
                <div fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                    <img src="../../../../assets/images/others/x_icon.png" alt="" class="rrss_icon">
                    <span><a _ngcontent-serverapp-c360="" href="{{'FOOTER.X_LINK' | translate}}" class="phone_link">{{ 'FOOTER.X_TEXT' | translate }}</a></span>
                </div>
            </a>
        </div>
       <!--  <div fxFlex="100" fxFlex.gt-sm="50" ngClass.sm="mt-2" ngClass.xs="mt-2"> 
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeControl]="true">
                <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"></agm-marker>
            </agm-map>
        </div> -->
    </div>
    <div fxLayout="row" fxLayoutAlign="center" class="copyright secondary-color theme-container">
        <div>{{'FOOTER.COPYRIGTH' | translate}} </div>
       <!--  <p>Designed & Developed by <a mat-button href="http://themeseason.com/" target="_blank">ThemeSeason</a></div> -->
    </div>
</footer>