<div class="modal_cp">
    <div class="icon-container">
        <mat-icon (click)="close()" >close</mat-icon>
      </div>
    
    <div mat-dialog-title>
        <div class="title_cp">
            <p>Ingresa tu código postal para</p>
            <p>calcular el costo de tu envío.</p>
        </div>
    </div>


    <div mat-dialog-content>
        <div bs-input="text" class="search-form m-5">

            <input type="text" class="input_cp" placeholder="Introduce tu Código Postal" (change)="valcp($event)">
            <div class="error" *ngIf="error">{{mensaje}}</div>

        </div>

    </div>

    <div mat-dialog-actions>

        <button color="primary" (click)="validCP()" class="mat-raised-button mat-button-base mat-primary btn_cp">
            <span class="mat-button-wrapper">Comenzar</span>
        </button>
    </div>
    <div class="version">Versión {{version}}</div>
</div>