<div class="banners-container" fxLayout="column" fxLayout.gt-sm="row" *ngIf="banners.length > 0">
    <!-- <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="40">
        <div class="banner" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage(0)">
            <div class="info" fxLayoutAlign="start start">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                    <h1 class="title">{{getBanner(0).title}}</h1>
                    <h3 class="subtitle">{{getBanner(0).subtitle}}</h3>
                    <a [routerLink]="['/products', getBanner(0).category]"mat-raised-button color="primary" class="mt-2">Compra ahora</a>
                </div> 
            </div> 
        </div>
        <div fxLayout="row" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage(1)">
                <div class="info" fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(1).title}}</h2>
                      <h4 class="subtitle">{{getBanner(1).subtitle}}</h4>
                      <a [routerLink]="['/products', getBanner(1).category]"mat-raised-button color="primary" class="mt-2">Compra ahora</a>
                    </div> 
                </div> 
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="60">
        <div fxLayout="row" fxFlexOrder="2" fxFlexOrder.gt-sm="1" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage(2)">
                <div class="info" fxLayoutAlign="space-around center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(2).title}}</h2>
                      <h4 class="subtitle">{{getBanner(2).subtitle}}</h4>
                      <a [routerLink]="['/products', getBanner(2).category]"mat-raised-button color="primary" class="mt-2">Compra ahora</a>
                    </div>                    
                </div>
            </div>
        </div>
        <div class="banner" fxFlexOrder="1" fxFlexOrder.gt-sm="2" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage(3)">
            <div class="info" fxLayout="column" fxLayoutAlign="center end">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                  <h1 class="title">{{banner.title}}</h1>
                  <h3 class="subtitle">{{banner.subtitle}}</h3>
                  <a [routerLink]="['/products', category]"mat-raised-button color="primary" class="mt-2">Compra ahora</a>
                </div>
            </div> 
        </div>
    </div>
   -->

   <div  class="swiper-container" [swiper]="config">
    <div class="swiper-wrapper h-100">      
        <div *ngFor="let banner of banners; let i = index" class="swiper-slide">
            <div class="banner" fxFlexOrder="1"  [ngStyle]="{'background-image': 'url(' + banner.image + ')'}">
                <div class="info" fxLayout="column" fxLayoutAlign="center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                      <h1 class="title">{{banner.title}}</h1>
                      <h3 class="subtitle">{{banner.subtitle}}</h3>
                      <a [routerLink]="['/products', getBanner(i).category]"mat-raised-button color="primary" class="mt-2">Compra ahora</a>
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <button mat-mini-fab class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
    <button mat-mini-fab class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button> 
</div>
    
</div>