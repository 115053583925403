import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../core/http.service';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  private API = {
    URL_PRODUCTS: environment.backUrl+`products`

  };

  constructor(private httpService: HttpService, private http: HttpClient) { }

  product() {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_PRODUCTS, 'GET', '');

    return observable;
  }
  public product_v1(operation, params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_PRODUCTS+"/"+params, operation, '');

    return observable;
  }
  public getProductById(id) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_PRODUCTS+"/"+id, 'GET', '');

    return observable;
  }

  public deleteProductById(id) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_PRODUCTS+"/"+id, 'DELETE', '', "admin");

    return observable;
  }
  public addProduct(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_PRODUCTS, 'POST', params, "admin");

    return observable;
  }
  public updateProduct(params,id) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_PRODUCTS+"/"+id, 'PUT', params, "admin");
    return observable;
  }
    
  public getProductByCategory(id) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_PRODUCTS+"/category/"+id, 'GET', '');
    return observable;
  }
  public combination(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_PRODUCTS+"/combination", 'POST', params);

    return observable;
  }
  public getImageURL(idParams){
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_PRODUCTS+"/images_urls/"+idParams, 'GET', '',"admin");

    return observable;
  }
  public uploadFile(url:string, images:File, imageType:string){
    let observable = new Observable();
    observable = this.httpService.sendImageRequest(url, 'PUT', images, imageType);

    return observable;
  }
  public getProductByName(name){
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_PRODUCTS+"/name/"+name, 'GET', '');

    return observable;
  }
  public getProductByTag(tag){
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_PRODUCTS+"/tag/"+tag, 'GET', '');

    return observable;
  }

  public variantsCombination(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_PRODUCTS+"/variants/combination", 'POST', params);

    return observable;
  }

}
