import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, AppService } from '../../app.service';
import { Product, Value, ResPost, Quantity, GuestLogin } from '../../app.models';
import { OrdersService } from '../../services/orders.service';
import { ViewportScroller } from "@angular/common";
import { Router } from "@angular/router";
import { UsersService } from '../../../app/services/users.service';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() product: Product;
  @Input() type: string;
  @Input() origin: string;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onCloseProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();

  public count: number = 0;
  public align = 'center center';
  public validVariants: Value[] = [];
  public banVariants = false;
  public showError = true;
  public spinner: boolean = false;
  public disabled: boolean = false;

  constructor(public appService: AppService, public snackBar: MatSnackBar, public ordersService: OrdersService,private scroller: ViewportScroller,private router: Router,public usersService: UsersService) { }


  ngOnInit() {
    this.banVariants = false;
    this.count = 1;
    if (this.product) {
      if (this.type !== 'wish') {
        if (this.product.variants.length > 0) {
          this.product.stock = this.product.combinations[0].stock;
          // this.product.price = this.product.combinations[0].price;
        }
      } else {
        this.count = this.product.quantity;
      }
    }
    this.layoutAlign();
  }

  public layoutAlign() {
    if (this.type == 'all') {
      this.align = 'space-between center';
    }
    else if (this.type == 'wish') {
      this.align = 'start center';
    }
    else {
      this.align = 'center center';
    }
  }

  public increment() {
    this.disabled = true;
    if (this.count < this.product.stock) {
      this.count++;
      let obj = {
        _id: this.product._id,
        soldQuantity: this.count,
        total: this.count * Number(this.product.price),
        variantsCombination: this.product.variantsCombination
      }
      if( this.type !== 'wish')
        this.disabled = false;
      this.changeQuantity(obj);
    }
    else {
      this.disabled = false;
      this.snackBar.open('No puedes añadir más productos de los que estan disponibles. En stock ' + this.count + ' productos.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }
  }

  public decrement() {
    this.disabled = true;
    if (this.count > 1) {
      this.count--;
      let obj = {
        _id: this.product._id,
        soldQuantity: this.count,
        total: this.count * Number(this.product.price),
        variantsCombination: this.product.variantsCombination
      }
      if( this.type !== 'wish')
        this.disabled = false;
      this.changeQuantity(obj);
    }
  }

  public addToCart(product: Product) {
    this.spinner = true;
    this.disabled = true;
    this.validVariants = [];
    product.variants.forEach(variant => {
      var val = variant.values.find(element => element.default);
      if (val !== undefined) {
        this.validVariants.push(val)
      }
    })

    if (this.validVariants.length === product.variants.length) {
      this.banVariants = false;
      if (this.product.stock > 0) {
        this.banVariants = false;
        var validVariants = [];
        this.product.variants.forEach(variant => {
          var val = variant.values.find(element => element.default);
          if (val !== undefined) {
            validVariants.push({ variantId: variant._id, value: val._id })
          }
        })

        var variantsCombination = {}

        validVariants.forEach(validVariant => {
          variantsCombination[validVariant.variantId] = validVariant.value
        })
        if (this.appService?.Data.order?.status !== undefined) {
          const params = {
            "product": {
              "productId": this.product._id,
              variantsCombination
            }
          }
          this.ordersService.getQuantityByProduct(params).subscribe((data: Quantity) => {
            ;
            this.count = data.quantity === 0 ? this.count : data.quantity + this.count;
            this.updateOrdersProduct(variantsCombination, product);
          });
        } else {
          this.addOrders(variantsCombination, product);
        }
      }else{
        this.spinner = false;
        this.disabled = false;
        this.snackBar.open('Este producto no está disponible.', '×', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
      }
    } else {
      this.banVariants = true;
    }

    if(this.banVariants){
      /* this.scroller.scrollToAnchor("variants_dialog"); */
      document.getElementById("variants_dialog").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
      /* this.router.navigate([], { fragment: "targetBlue" }); */
      this.spinner = false;
      this.disabled = false;
      this.snackBar.open('Selecciona todas las opciones.', '×', { panelClass: ['error'], verticalPosition: 'top', duration: 3000 });
    }        
        
  }

  public addOrders(variantsCombination, product) {
    const params = {
      "order": {
        "token": "",
        "products": [
          {
            "quantity": this.count,
            "productId": product._id,
            variantsCombination
          }
        ]
      }
    }
    if (!localStorage.getItem('tokenFuerza') || localStorage.getItem('tokenFuerza') == "undefined"){
      this.usersService.guestLogin().subscribe((res: GuestLogin) => {
        const response: GuestLogin = {
          token: res.token
        };
        localStorage.setItem('tokenFuerza', response.token);
        console.log("Token:"+localStorage.getItem('tokenFuerza'));
        this.ordersService.addOrders(params).subscribe((data: ResPost) => {
          if (data.mensaje === 'Error 200--OK') {
            this.dataOrdersServe(product);
          } else {
            this.snackBar.open('Ha ocurrido un error, por favor inténtelo de nuevo.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
            return false;
          }
    
        });
      });
     }else{
      this.ordersService.addOrders(params).subscribe((data: ResPost) => {
        if (data.mensaje === 'Error 200--OK') {
          this.dataOrdersServe(product);
        } else {
          this.snackBar.open('Ha ocurrido un error, por favor inténtelo de nuevo.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          return false;
        }
  
      });
     }
    
  }

  public updateOrdersProduct(variantsCombination, product) {
    const params = {
      "product": {
        "productId": product._id,
        "quantity": this.count,
        variantsCombination
      }
    }
    this.ordersService.addOrdersProduct(params).subscribe((data: ResPost) => {
      if (data.mensaje === 'Error 200--OK') {
        this.dataOrdersServe(product);
      } else {
        this.snackBar.open('Ha ocurrido un error, por favor inténtelo de nuevo.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        return false;
      }

    });
  }

  public dataOrdersServe(product) {
    var currentProduct
    currentProduct = this.appService.Data.cartList.filter(item => item.productId == product.productId)[0];
    if (currentProduct) {
      if ((currentProduct.cartCount + this.count) <= this.product.stock) {
        product.cartCount = currentProduct.cartCount + this.count;
      }
      else {
        this.snackBar.open('Ya no puedes añadir más productos. En stock ' + this.product.stock + ' productos y tu ya has añadido ' + currentProduct.cartCount + ' productos en tu carrito', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        return false;
      }
    } else {
      product.cartCount = this.count;
    }
    this.appService.addToCart(product);
    this.closeProductDialog();
  }

  public openProductDialog(event) {
    this.onOpenProductDialog.emit(event);
  }

  public closeProductDialog() {
    this.showError = false;
    this.onCloseProductDialog.emit();
  }

  public changeQuantity(value) {
    this.onQuantityChange.emit(value);
    setTimeout(function(){
      this.disabled = false;
    }, 20000);
  }

}