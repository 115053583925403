import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../core/http.service';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CatalogsService {

  private API = {
    URL_CATALOGS: environment.backUrl+`catalogs`
  };

  constructor(private httpService: HttpService, private http: HttpClient) { }

  catalogs(operation, params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_CATALOGS, operation, params);

    return observable;
  }
  public getCatalogsByLabel(label) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_CATALOGS+"/"+label, 'GET', '','sinToken');

    return observable;
  }
  public getPaymenthValues(deliveryMethod) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_CATALOGS+"/method_payment/"+deliveryMethod, 'GET', '');

    return observable;
  }


}
