import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../core/http.service';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private API = {
    URL_ORDERS: environment.backUrl+`orders`
  };

  constructor(private httpService: HttpService, private http: HttpClient) { }

  public getOrders() {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS, 'GET', '', "admin");

    return observable;
  }

  public getOrdersWithFilters(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS+'?'+params, 'GET', '', "admin");

    return observable;
  }

  public addOrders(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS, 'POST', params);

    return observable;
  }

  public getOrdersBytoken() {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS + '/bytoken', 'GET', '');

    return observable;
  }

  public addOrdersProduct(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS + '/product', 'PATCH', params);

    return observable;
  }

  public removeOrdersProduct() {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS + '/bytoken', 'DELETE', '');

    return observable;
  }
  public addDeliveryInfo(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS + '/delivery-info', 'POST', params);

    return observable;
  }
  public addPaymentMethod(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS + '/payment-method', 'POST', params);

    return observable;
  }
  public addDeliveryAddress(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS + '/delivery-address', 'POST', params);

    return observable;
  }
  public getQuantityByProduct(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS + '/product/quantity', 'POST', params);

    return observable;
  }
  public updateOrderStatus(id,params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS + '/status/'+id, 'POST', params, "admin");

    return observable;
  }
  public confirmOrder() {
    let user = localStorage.getItem("userLoggedDataBD") != null ? "client" : "";
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS + '/confirm-order', 'PATCH', '', user);

    return observable;
  }
  public getOrdersBystatus(status) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS+'/status/'+status, 'GET', '', "admin");

    return observable;
  }
  public getOrdersBystatusWithFilters(status,params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS+'/status/'+status+'?'+params, 'GET', params, "admin");

    return observable;
  }
  public getOrdersById(id) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS+'/public/'+id, 'GET', '');

    return observable;
  }
  public combination(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS+"/product/combination", 'POST', params);

    return observable;
  }
  public getShippingPrice(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS + '/shipping-price', 'POST', params);

    return observable;
  }
  public getShippingAddress(zipCode) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS + '/shipping-address/'+zipCode, 'GET', '');

    return observable;
  }

  /* logued user orders */
  public getClientOrders() {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS + '/client/', 'GET', '',"client");
    return observable;
  }
  public deliveryAddress(params) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS+"/delivery-address", 'POST', params);

    return observable;
  }
  public linkOrder() {
    let params = '{"token": "'+localStorage.getItem("tokenFuerza")+'"}';
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS+"/link", 'PATCH', params, "client");

    return observable;
  }
  /* logued user orders */
  
  public getDeliverySchedulers() {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS+"/delivery-schedules", 'GET', '');

    return observable;
  }

  public getDiscountCoupon(code) {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_ORDERS+"/discount-coupon" + '?code=' + code, 'GET', '');

    return observable;
  }

}